import { TemplateElement } from '../../01-base/TemplateElement';
import style from './SvgIcon.css';

class SvgIcon extends TemplateElement {
    constructor() {
        super(null, { shadowRender: false, styles: [style] });
    }

    properties() {
        return {
            name: '',
            spritePath: window.siteConfig().spritePath,
        };
    }

    template() {
        return `
            <svg>
                <use xlink:href="${this.spritePath}#${this.name}"></use>
            </svg>
        `;
    }
}

try {
    customElements.define('svg-icon', SvgIcon);
} catch (e) {}
